.Container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  border: 1px solid #ffffff91;
  border-radius: 15px;
}

.labelText {
  color: #425466;
  text-transform: capitalize;
  font-family: "Inter-regular";
  font-size: 14px;
  padding: 0px 5px;
}

.inputBox {
  font-size: 16px;
  color: #000;
  letter-spacing: 1.4px;
  padding: 15px 20px;
  width: 100%;
  background-color: transparent;
  outline: none;
  border: 1px solid var(--border-color);
}

.inputPassContainer {
  display: flex;
  background: var(--white-color);
  position: relative;
  overflow: hidden;
  box-shadow: 2px 3px 8px 2px #a1a1a324;
}

.inputPassContainer input::placeholder {
  color: var(--placeholder-color);
  font-family: "Inter-regular";
  font-size: 12px;
  text-transform: capitalize;
}

.phone-field {
  width: 100%;
  border: none;
}
.phoneInput-wrapper > div {
  width: 100%;
}
.phoneInput-wrapper > div > input {
  width: 100% !important;
  display: inline-block;
}
.phoneInpViewOnly {
  max-width: 200px !important;
}
.phoneInpViewOnly > div {
  border: none;
}
.phoneInpViewOnly .flag-dropdown {
  width: auto;
}
.phoneInpViewOnly > div > input,
.phoneInpViewOnly > div > input:hover,
.phoneInpViewOnly > div > input:focus,
.phoneInpViewOnly > div > input:active {
  cursor: default !important;
  border-color: none !important;
  background-repeat: no-repeat;
  box-shadow: none !important;
  border: 0px !important;
}
.phoneInputReadOnly
  > .react-tel-input
  > .flag-dropdown
  > .selected-flag
  > .flag {
  display: none !important;
}
.phoneInpViewOnly .react-tel-input .selected-flag:focus:before {
  border-color: none !important;
  box-shadow: none !important;
  border-color: transparent !important;
}
.phoneInpViewOnly > .react-tel-input .flag-dropdown {
  display: none !important;
}

.inputBoxSalutation {
  width: 20% !important;
  padding: 15px 0px;
  padding-left: 10px;
}

@media (max-width: 578px) {
  .inputBoxSalutation {
    width: 20%;
  }
}
