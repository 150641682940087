.imageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  opacity: 0.8;
}
.imageWrapper > p {
  font-size: 18px;
  font-weight: 600;
}
.imageWrapper > img {
  border-radius: 100%;
}
.selected {
  border: 2px solid var(--main-color);
  opacity: 1 !important;
}
