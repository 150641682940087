.main-wrapper {
  width: 100%;
  padding: 20px 10px;
  display: flex;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid var(--main-background);
}

.main-wrapper img {
  height: 40px;
  border-radius: 100%;
}

.main-wrapper > div > p {
  font-size: 16px;
  font-weight: 400;
  color: var(--slate-gray);
  line-height: 20px;
}

.main-wrapper > div > p > span {
  font-size: 16px;
  font-weight: 400;
  color: var(--main-color);
  text-decoration: underline;
  margin-left: 5px;
}
.main-wrapper > div > h6 {
  color: var(--main-color);
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}
