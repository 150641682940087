.table__title {
  font-size: 16px;
  font-family: "Inter-semiBold";
}

.table__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  gap: 6px;
  background: var(--white-color);
}

.show-pages-and-colummns {
  display: flex;
  align-items: center;
  gap: 12px;
}
.pageIndicator {
  flex: 1;
  white-space: nowrap;
}
.filter__actionWrapper {
  display: flex;
  gap: 1rem;
}

.pageValue {
  color: var(--medium-gray);
}

.pageValue__wrapper {
  padding: 0px !important;
  width: fit-content;
  border-radius: 6px;
  height: 100%;
}
.adjust-action {
  margin-bottom: 0.23rem;
}
.page-indicator-wrapper {
  display: flex;
  gap: 1rem;
  align-items: center;
}

@media (max-width: 767px) {
  .show-pages-and-colummns p {
    font-size: 13px;
  }
  .table__header {
    flex-direction: column;
    align-items: start;
  }
}

@media (max-width: 440px) {
  .show-pages-and-colummns {
    flex-direction: column;
    align-items: start;
  }
}
