/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

:root {
  --main-background: #f1f4f9;
  --blue-gray: #a0aec0;
  --gainsboro: #edf2f7; /*greish color use for line seprator  */
  --slate-gray: #425466;
  --main-color: #0059a9;
  --text-color-gray: #7a7a9d;
  --medium-gray: #b5b5bd;
  --white-color: #ffffff;
  --text-color-black: #000000;
  --placeholder-color: #a19a9a;
  --sidebar-text-color: #8f9299;
  --footer-text-color: #d1d1d1;
  --primary-color: #4aeab1;
  --secondary-color: #19262b;
  --purple-color: #401f51;
  --text-dark-gray: #808080;
  --label-color: #9b9b9b;
  --disabled-input-color: #fafafa;
  --disabled-label-color: #ababab;
  --error-color: red;
  --secondary-color: #ffc10d;
  --secondary-color: #eff0f6;
  --border-radius: 16px;
  --primary-padding: 10px;
  --primary-margin: 10px;
  --silver: #b5b5bd;
  --raven: #22293f;
  --ghost-white: #edf2f7;
  --cadet-blue: #8492a6;
  --steel-gray: #7a828a;
  --coral: #f7936f;
}

.c-p {
  cursor: pointer !important;
}

body {
}

/* Poppins */

@font-face {
  font-family: "Inter-bold";
  src: url("./assets/fonts/Inter/Inter-Bold.ttf");
}

@font-face {
  font-family: "Inter-medium";
  src: url("./assets/fonts/Inter/Inter-Medium.ttf");
}

@font-face {
  font-family: "Inter-regular";
  src: url("./assets/fonts/Inter/Inter-Regular.ttf");
}

@font-face {
  font-family: "Inter-extraBold";
  src: url("./assets/fonts/Inter/Inter-ExtraBold.ttf");
}
@font-face {
  font-family: "Inter-semiBold";
  src: url("./assets/fonts/Inter/Inter-SemiBold.ttf");
}

/*//////////////////////////////////////////////////////////////////
  [ RESTYLE TAG ]*/

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

/*---------------------------------------------*/

a.link {
  font-family: "Inter-regular";
  font-size: 14px;
  line-height: 1.7;
  color: var(--d1d1d1-clr);
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
}

body p {
  font-size: 16px;
  font-family: "Inter-regular";
}

.customPara {
  font-size: 16px;
  font-family: "Inter-regular";
}

body h1 {
  font-size: 57px;
  font-family: "Inter-bold";
}

body h2 {
  font-size: 40px;
  font-family: "Inter-regular";
}

body h3 {
  font-size: 36px;
  font-family: "Inter-regular";
}

body h4 {
  font-size: 28px;
  font-family: "Inter-regular";
  font-weight: 400;
}

body h5 {
  font-size: 24px;
  font-family: "Inter Tight", sans-serif;
  font-weight: 400;
}

body h6 {
  font-size: 22px;
  font-family: "Inter Tight", sans-serif;
  font-weight: 400;
}

/*---------------------------------------------*/

h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
a,
svg {
  margin: 0px;
}
body p {
  font-family: "Inter-regular";
}
body p.large {
  font-size: 24px;
  font-family: "Inter-semiBold";
}

body p.reg {
  font-size: 22px !important;
  font-family: "Inter-bold" !important;
}

.p18 {
  font-size: 18px;
  font-family: "Inter-regular";
}

ul,
li {
  margin: 0px;
  list-style-type: none;
}

/*---------------------------------------------*/

input {
  outline: none;
  border: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* / Firefox / */
input[type="number"] {
  -moz-appearance: textfield;
}

body.dark-mode {
  background-color: #333;
  color: #fff;
}

body.light-mode {
  background-color: #fff;
  color: #333;
}

/*
=========
utility classes
*/

.border-radius {
  border-radius: 6px;
}
.mb8px {
  margin-bottom: 8px;
}

.align_grid_1rem_gap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.flex_grid_justify-content-center {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.flex_1rem_gap {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.flex_grid_justify-content-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.button_transparent {
  border-color: transparent;
  background: transparent !important;
  outline: none;
}
.pagination-wraper .css-1fdsijx-ValueContainer {
  padding: 8px !important;
}
.pagination-wraper .css-1u9des2-indicatorSeparator {
  display: none !important;
}

.pagination-wraper .css-11hb7zxy-IndicatorsContainer {
  display: none !important;
}
.pagination-wraper .css-1xc3v61-indicatorContainer {
  display: none !important;
}
.pagination-wraper > div > div {
  margin-top: 0 !important;
}
.pagination-wraper .css-11hb7zxy-IndicatorsContainer {
  display: none !important;
  background-color: red !important;
}
.form-check-input:checked {
  background-color: var(--main-color) !important;
  border-color: var(--main-color) !important;
}

@media (max-width: 768px) {
  .form-wrapper-custom > .row {
    display: flex;
    /* flex-direction: column; */
    /* gap: 20px; */
    /* margin-bottom: 20px; */
  }

  .Faculty_filters-wrapper__SEjN8 > .row > .col-lg-6,
  .Faculty_filters-wrapper__SEjN8 > .row > .col-lg-3 {
    margin-bottom: 20px;
  }
}
.col-md-12,
.col-sm-12 {
  margin-bottom: 10px;
}
.Dashboard_caseCard__UjnI- .row {
  width: 100%;
  flex: 1;
}

.css-hlgwow {
  padding: 10px 8px !important;
}
