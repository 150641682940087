.loading-wave {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999;
}

.loading-bar {
  width: 20px;
  height: 10px;
  margin: 0 5px;
  background-color: var(--main-color);
  border-radius: 5px;
  animation: loading-wave-animation 1s ease-in-out infinite;
}

.loading-bar:nth-child(2) {
  animation-delay: 0.1s;
}

.loading-bar:nth-child(3) {
  animation-delay: 0.2s;
}

.loading-bar:nth-child(4) {
  animation-delay: 0.3s;
}

@keyframes loading-wave-animation {
  0% {
    height: 10px;
  }

  50% {
    height: 50px;
  }

  100% {
    height: 10px;
  }
}
