.extraCardStyles {
  border: none !important;
}
.heading-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.heading-wrapper h1 {
  font-size: 28px;
  font-weight: 600;
}

.heading-wrapper p {
  font-size: 14px;
  font-weight: 400;
}

.action-wrapper {
  display: flex;
  justify-content: end;
  gap: 10px;
}

.cancel-button {
  background-color: var(--secondary-color);
  color: var(--slate-gray);
  min-width: 100px;
}
.signIn-button {
  padding: 10px 25px;
}
.extraLoaderStyles {
  height: 80vh;
}
