.button {
  background-color: var(--main-color);
  border-color: transparent;
  border: none;
  outline: none;

  text-wrap: nowrap;
  margin-left: 3px;
  padding: 10px;
  border-radius: 8px;
  color: #fff;
  min-width: 100px;
}

.button:disabled {
  background-color: grey;
}
