.filters-wrapper {
  margin: 20px;
  margin-bottom: 0px;
}
.main-div {
  width: 100%;
  margin: 20px 0px;
  padding: 0px 20px;
}

.extraStyle {
  padding: 0px 15px !important;
}

.no-noti {
  padding: 50px;
  text-align: center;
  color: var(--slate-gray);
  font-size: 20px;
}
