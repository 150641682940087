.cardStyle {
  padding: 10px 0px;
  height: 290px;
}
.imageWrapper {
  padding: 10px 20px;
  display: flex;
  align-items: center;
}

.h-100 {
  height: 100% !important;
}
.imageWrapper > img {
  height: 245px;
  width: 100%;
  border-radius: 16px;
}
.description-wrapper {
  margin: 0px;
  padding: 0px;
  margin-top: 10px;
}
.main-heading h1 {
  font-size: 24px;
  font-weight: 800;
}

.main-heading > span {
  font-size: 12px;
  font-weight: 400;
}

.details {
  height: 200px;
  padding: 0px;
}

.details > ul {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 200px;
  margin-left: -10px;
}
.details > ul > li {
  font-size: 12px;
  font-weight: 400;
  color: var(--slate-gray);
  list-style-type: disc;
}

.details > ul > li::marker {
  font-size: 18px !important;
}

@media (max-width: 1024px) {
  .cardStyle {
    height: 400px;
  }
  .imageWrapper {
    margin-top: 5px;
  }
  .imageWrapper > img {
    height: 150px;
  }
  .description-wrapper {
    padding: 0px 10px 0px 20px;
    margin: 0px;
  }
  .main-heading {
    padding: 0px 0px 10px 5px;
  }
  .main-heading > h1 {
    font-size: 16px;
  }
  .details > ul > li::marker {
    font-size: 12px !important;
  }
}

@media (max-width: 425px) {
  .cardStyle {
    height: 400px;
    padding: 0px 20px !important;
  }
  .imageWrapper {
    margin-top: 15px;
  }
  .details > ul {
    height: 140px;
  }
  .main-heading > h1 {
    font-size: 14px;
  }
}
