.main-wrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #27272e;
  width: 90%;
  margin: auto;
}
.extraCardStyles {
  max-width: 45rem;
  width: 100%;
  min-height: 865px;
}

.brand-logo {
  display: flex;
  justify-content: center;
  margin: 20px;
}
.brand-logo img {
  height: 75px;
}

.main-heading {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.main-heading h1 {
  font-size: 20px;
  font-weight: 600;
}

.main-heading p {
  font-size: 14px;
  font-weight: 400;
}

.action-wrappper {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}
.btn {
  padding: 10px 20px;
  min-width: 80px;
}
.custom-btn {
  background-color: #dee2e6;
  color: var(--slate-gray);
}

.step-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
}
.ind-step {
  display: block;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  font-weight: 600;
  font-size: medium;
  text-align: center;
  padding-top: 5px;
}
.ind-step-done {
  background-color: var(--main-color);
  color: #fff;
}
.ind-step-remaining {
  background-color: var(--secondary-color);
  color: var(--slate-gray);
}
.ind-step-marker {
  display: block;
  width: 80px;
  height: 5px;
  margin: 0px 10px;
  background-color: var(--main-color);
  border-radius: 20%;
}
.ind-step-marker-done {
  background-color: var(--main-color);
}
.ind-step-marker-process {
  background: linear-gradient(
    to right,
    var(--main-color) 50%,
    var(--secondary-color) 50%
  );
}
.ind-step-marker-remaining {
  background-color: var(--secondary-color);
}
