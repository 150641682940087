.extraCardStyles {
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px 20px;
}
.heading-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.heading-wrapper h1 {
  font-size: 28px;
  font-weight: 600;
}

.details-wrapper {
  padding: 50px 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.detail-title h2 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 10px;
}

.detail-description h3 {
  font-weight: 500;
  font-size: 18px;
}
.name-h3 {
  color: var(--main-color);
  font-weight: 600 !important;
}
.action-wrapper {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.cancel-button {
  background-color: var(--secondary-color);
  color: var(--slate-gray);
  width: 50%;
  /* min-width: 100px; */
}

.icon-wrapper {
  width: 70px;
  height: 70px;
  background-color: var(--main-color);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-img {
  display: flex;
  justify-content: center;
  align-self: center;
}
.profile-img > img {
  height: 150px;
  max-width: 170px;
  border-radius: 100%;
}
