.select-wrapper {
  border: 1px solid var(--edge-color);
  box-shadow: 2px 3px 8px 2px #a1a1a324;
  margin-top: 8px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.label {
  color: #425466;
  text-transform: capitalize;
  font-family: "Inter-regular";
  font-size: 14px;
  padding: 0px 5px;
}

.disabled {
  color: red;
}

.noBorder {
  border: none;
}
