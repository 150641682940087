.main-wrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #27272e;
  overflow-x: hidden;
  width: 90%;
  margin: auto;
}

.action-wrapper {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.cancel-button {
  background-color: var(--secondary-color);
  color: var(--slate-gray);
  min-width: 100px;
}

.cancel-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.cancel-icon svg {
  cursor: pointer;
}

.cancel-icon p {
  font-size: 12px;
  font-weight: 400;
  color: var(--slate-gray);
}
