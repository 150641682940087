.wrapper {
  border-radius: 25px;
  overflow: hidden;
  background-color: var(--white-color);
  box-shadow: 0px 0px 1px 0px #0c1a4b3d;
}
.pageIndicator {
  font-family: "Inter-regular";
  font-size: 14px;
  line-height: 14px;
  display: flex;
}
.table-item-checkbox {
  accent-color: var(--main-color);
}
.topHeaderBox input {
  width: 16px !important;
  height: 16px !important;
}
/* .table-item-checkbox input[type="checkbox"] {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  outline: none;
  content: none;
  cursor: pointer;
} */

/* .table-item-checkbox  input[type="checkbox"]:before {
  font-family: "FontAwesome";
  content: "\f00c";
  color: transparent !important;
  background: transparent;
  display: block;
  width: 16px;
  height: 16px;
  border: 1px solid var(--light-slate-gray);
  border-radius: 3px;
  position: relative; /
  top: 1px; 
}
.table-item-checkbox input[type="checkbox"]:checked:before {
  color: #fff !important;
  background: var(--main-color);
  padding-right: 0px;
  padding-left: 2px;
  padding-top: 2px;
  font-weight: lighter;
} */

.rowAction {
  border: 1px solid var(--ghost-white);
  color: var(--raven);
}

/* PatientDataTable.module.css */

.scrollableContainer {
  overflow-x: auto;
  overflow-y: auto;
  position: relative; /* Position relative for pseudo-element */

  height: calc(100vh - 286px);
}

/* Customize scrollbar */
.scrollableContainer::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
  height: 8px;
}

/* Track */
.scrollableContainer::-webkit-scrollbar-track {
  background: transparent; /* Transparent background */
}

/* Handle */
.scrollableContainer::-webkit-scrollbar-thumb {
  background: var(--main-color); /* Gradient background */
  border-radius: 10px; /* Rounded corners */
}

/* Handle on hover */
.scrollableContainer::-webkit-scrollbar-thumb:hover {
  background: var(--main-color); /* Gradient background */
  animation: loading 2s infinite; /* Add loading animation on hover */
}

/* Keyframe animation for loading effect */
@keyframes loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

@media (max-width: 767px) {
  .scrollableContainer {
    overflow-x: auto;
    overflow-y: hidden; /* Hide vertical scrollbar on small screens */
    max-height: none; /* Reset max-height for small screens */
    width: 100%; /* Ensure the container takes up the full width */
  }
}

.popoverDiv {
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
}

.popoverDiv > li {
  padding: 5px 20px;
  /* color: var(--text-color-black); */
  font-weight: 500;
  cursor: pointer;
  border-radius: 6px;
}
.popoverDiv > li:hover {
  color: var(--white-color);
  background-color: var(--main-color);
}
