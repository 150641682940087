.main-div {
  width: 100%;
  padding: 0px 20px;
}

.filters-wrapper {
  margin: 20px;
  margin-bottom: 0px;
}

.extraInputBox {
  padding: 10px 20px;
}

.recent-wrapper {
  /* padding: 25px; */
}

.main-div {
  width: 100%;
  padding: 0px 20px;
}

.filters-wrapper {
  margin: 20px;
  margin-bottom: 0px;
}

.extraInputBox {
  padding: 10px 20px;
}

.extraStyles {
  padding: 10px !important;
}

.pdfImage {
  height: 60px;
}
.wordImage {
  height: 55px;
}

.recent-fileName {
  font-weight: 600;
  color: var(--main-color);
  font-size: 16px;
}

.recent-fileDesc {
  font-size: 14px;
}

.recentCardRow {
  align-items: center;
}

@media (max-width: 991px) {
  .recent-fileDesc {
    font-size: 12px;
  }
  .recent-fileName {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .extraStyles {
    text-align: center;
  }
}

@media (max-width: 992px) {
  .recent-fileDesc {
    font-size: 12px;
    margin-left: 5px;
  }
  .recent-fileName {
    font-size: 14px;
  }
}

@media (max-width: 1200px) {
  .extraStyles {
    margin-bottom: 10px;
  }
}
