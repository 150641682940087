.container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 10px;
  padding: 25px;
}
.heading-wrapper {
  padding: 10px 0px;
}
.heading-wrapper h1 {
  font-size: 28px;
  font-weight: 600;
}
.heading-wrapper p {
  font-size: 14px;
  font-weight: 400;
}
.upload-area {
  border: 2px dashed #ccc;
  height: 100%;
  /* max-height: 140px; */
  border-radius: 4px;
  padding: 50px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 20px;
  transition: border-color 0.3s;
}
.upload-area:hover {
  border-color: var(--main-color);
}
.upload-area input {
  display: none;
}
.file-list {
  width: 100%;
  border: 1px solid var(--secondary-color);
  border-radius: var(--border-radius);
}

.file-item {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #f0f0f0;
}
.file-icon {
  width: 40px;
  height: 40px;
  background-color: #f0f0f0;
  border-radius: 4px;
  margin-right: 10px;
}
.file-icon img {
  height: 40px;
}

.progress-bar {
  margin: 30px;
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 4px;
  overflow: hidden;
}
.progress {
  background-color: var(--main-color);
  height: 10px;
  width: 0;
  transition: width 0.3s ease-in-out;
}
