.extraCardStyles {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cross-icon {
  position: fixed;
  top: 5px;
  right: 10px;
  cursor: pointer;
  color: var(--main-color);
  font-size: 36px;
  font-weight: 600;
  line-height: 25px;
}

.btn-wrapper {
  display: flex;
  position: fixed;
  /* flex-direction: column; */
  z-index: 2;
  top: 5px;
  left: 5px;
}

.btn {
  min-width: 20px;
  padding: 0px 5px;
  padding-bottom: 5px;
  margin-top: 0px;
  margin: 2px 0px;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  background-color: transparent;
  color: var(--main-color);
  /* border: 1px solid var(--main-color); */
}

@media (max-width: 425px) {
  .image {
    margin-top: 20px;
    height: 450px;
  }
  .btn {
    font-size: 18px;
    padding: 0px 5px;
    line-height: 15px;
    padding-bottom: 2px;
  }
}

@media (max-width: 375px) {
  .image {
    height: 400px;
  }
  .cross-icon {
    font-size: 32px;
  }
}
