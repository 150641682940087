.extraCardStyles {
  max-width: 425px;
}

.brand-logo {
  display: flex;
  justify-content: center;
  margin: 20px;
}
.brand-logo img {
  height: 30px;
}

.main-heading {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.main-heading h1 {
  font-size: 20px;
  font-weight: 600;
}

.main-heading p {
  font-size: 14px;
  font-weight: 400;
}

.extra-wrapper {
  display: flex;
  justify-content: space-between;
}
.rememberMe {
  display: flex;
  gap: 8px;
}
.rememberMe input {
  accent-color: #0059a9;
  transform: scale(1.2);
}
.rememberMe label {
  color: #5a6979;
}

.forgetPass a {
  color: #0059a9;
  text-decoration: none;
}

.signIn-button {
  padding: 8px !important;
  border-radius: 8px;
}

.or-span {
  font-size: 18px;
  text-align: center;
  color: #7a7a9d;
  display: flex;
  justify-content: center;
}

.register-wrapper {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 5px;
}
.register-wrapper span {
  color: #425466;
}
