.cardStyle {
  padding: 0px;
  height: 430px;
  /* max-height: 350px; */
  /* align-items: center; */
  /* max-width: 70vw; */
}
.imageWrapper {
  padding: 40px 20px;
  padding-left: 30px;
}

.h-100 {
  height: 100% !important;
}
.imageWrapper > img {
  height: 320px;
  min-height: 100%;
  width: 100%;
  border-radius: 16px;
}

.main-heading {
  /* display: flex;
  flex-direction: column;
  gap: 10px; */
}
.main-heading h1 {
  font-size: 24px;
  font-weight: 800;
}

.main-heading p {
  /* font-size: 14px;
  font-weight: 500;
  color: var(--slate-gray); */
}

.description-wrapper {
  margin: 0px;
  margin-top: 20px;
  padding: 5% 15px 0px 0px;
}
.details {
  padding: 5% 20px;
  padding-left: 0px;
}

.details > ul {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 200px;
  margin-left: -10px;
}
.details > ul > li {
  font-size: 12px;
  font-weight: 400;
  color: var(--slate-gray);
  list-style-type: disc;
}

.details > ul > li::marker {
  font-size: 18px !important;
}

@media (max-width: 1440px) {
  .cardStyle {
    height: 480px;
    padding: 10px 0px;
  }
  .details > ul {
    height: 260px;
  }
}

@media (max-width: 1024px) {
  .cardStyle {
    height: 600px;
  }
  .imageWrapper {
    height: 200px !important;
    margin-top: 15px;
    padding: 20px;
  }
  .imageWrapper > img {
    height: 100%;
    min-height: 100%;
  }
  .description-wrapper {
    padding: 0px 10px 0px 20px;
    margin: 0px;
  }
  .main-heading {
    padding: 0px 10px 0px 5px;
  }
  .main-heading > h1 {
    font-size: 16px;
  }
  .details > ul {
    height: 190px;
  }
  .details > ul > li {
    font-size: 10px !important;
  }
  .details > ul > li::marker {
    font-size: 12px !important;
  }
}

@media (max-width: 768px) {
  .cardStyle {
    height: 400px;
  }
}

@media (max-width: 425px) {
  .details > ul {
    height: 190px;
    margin-left: -20px;
  }

  .main-heading > h1 {
    font-size: 14px;
  }

  .details {
    padding: 5% 0px;
  }
  /* .details > ul {
    height: 150px;
  } */
  .imageWrapper {
    margin-top: 0px;
  }
}
