.carousel-container {
  position: relative;
  /* max-width: 90vw; */
  margin: auto;
}

.slick-track {
  display: flex;
  align-items: center;
}

/* .carousel-slide {
  transition: transform 0.5s ease-in-out;
} */

.card {
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
  padding: 10px;
}

.card img {
  width: 100%;
  border-bottom: 1px solid #ddd;
}

.card-content {
  padding: 15px;
}

.card-content h3 {
  margin: 0 0 10px;
  font-size: 1.5em;
}

.card-content p {
  margin: 0;
  font-size: 1em;
  color: #666;
}

.slick-slider {
  display: flex;
  align-items: center;
}

.slick-slide {
  opacity: 0.5;
  transition: opacity 0.5s ease, transform 0.5s ease;
  transform: scale(0.8);
  padding-top: 0px;
  /* width: 650px !important; */
}

.slick-list {
  max-height: 500px !important;
  margin-bottom: 20px;
}

.slick-center {
  opacity: 1 !important;
  transform: scale(1.1) !important;
  z-index: 1;
}

.arrow {
  font-size: 2rem;
  line-height: 1;
  cursor: pointer;
  color: var(--main-color);
  z-index: 2;
}

.slick-dots {
  bottom: -10px;
}

.slick-dots li button:before {
  font-size: 10px;
  color: var(--main-color);
}

.slick-dots li.slick-active button:before {
  color: var(--main-color);
}

.slick-slide.slick-active.slick-center.slick-current {
  /* margin: 0px 20px; */

  /* opacity: 1; */
}
