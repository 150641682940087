/* .overflow-x-hidden {
  overflow-x: hidden;
} */

.sidebar__wrapper {
}

.sidebar__wrapper aside {
  overflow-y: scroll;
  height: 100vh;
  border-right: 1px solid #e7e4e4;
  position: fixed;
  z-index: 200;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.sidebar__sideContent {
  width: 300px;
  background: var(--neutral-main-bg) !important;
  height: 100%;
}
.sidebar__wrapper aside::-webkit-scrollbar {
  display: none;
}
.sidebar__mainContent {
  margin-left: 250px;
  background-color: var(--neutral-main-bg);
  transition: 0.3s ease-in-out;
  height: 100vh;
  overflow-y: scroll;
  width: 100%;
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;
}
.sidebar__mainContent::-webkit-scrollbar-thumb {
  display: none;
}
.sidebar__mainContent::-webkit-scrollbar {
  width: 0;
}
.width-0 {
  width: 0 !important;
}
.margin-left-0 {
  margin-left: 0;
}
.sidebarList {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-x: hidden;
  height: 100%;
}
.inputSearch {
  padding: 10px 15px;
  padding-block-start: 40px;
}

.sidebar__openerToggle {
  position: fixed;
  inset-inline-start: -40px;
  top: 15px;
  transition: 0.6s ease-in-out;
  width: 35px;
  transition-delay: 0.3s;
  background-color: var(--main-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 11px !important;
  z-index: 5 !important;
  border-radius: 0px 4px 4px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.show__openerToggle {
  left: 0;
}
.sidebar__wrapper {
  display: flex;
  height: 100vh;
}
.sidebar-sdide-transition {
  transition: 0.3s ease-in-out;
}

.sidebar__header p {
  color: var(--neutral-main);
  font-weight: bold;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  margin: 0;
}
.sidebar__header p:hover {
  color: var(--main-color);
  text-decoration: underline;
}
.sidebar__header {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  overflow-x: hidden;
}
.searchbar-styling {
  background: #fff;
  border-radius: 0.375rem;
  height: 38px;
  border: 1px solid #dee2e6;
  overflow-y: hidden;
  width: 247.19px;
}
.searchbar-container {
  padding: 10px 15px;
}
.searchbar-styling > div > div input {
  background: white;
  border-right: 1px solid #dee2e6;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.08));
  padding: 0.375rem 0.75rem;
}
/* .content-wrapper {
    height: calc(100vh - 140px);
    overflow-y: scroll;
  } */
.menuItem {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px 10px 14px !important;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.markAsActive {
  position: relative;
}
.markAsActive::before {
  position: absolute;
  content: " ";
  width: 2px;
  height: 100%;
  background-color: var(--main-color);
  left: 0;
}
.markAsActive > .menuContent > p {
  color: var(--main-color) !important;
}

.markAsActive > .menuContent > .menuIcon-wrapper > svg {
  fill: var(--main-color) !important;
}
.menuItem::before {
}
.menuItem:hover {
  background: linear-gradient(
    270deg,
    rgb(0 89 169 / 3%) 0%,
    rgb(0 89 169 / 9%) 100%
  );
}

.menuItem p {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 600;
  color: var(--neutral-main) !important;
}
.menuItem:hover p {
  color: var(--main-color) !important;
}
.activeMenu {
  font-size: 1rem !important;
  transition: all 0.3s ease-in-out;
}
.activeMenu > div i {
  color: var(--main-color) !important;
}
.activeMenu p {
  color: var(--main-color) !important;
}
.show-list-item {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  transition: all 0.5s ease-in-out;
  position: relative;
  padding-left: 18px;
  cursor: pointer;
}
.show-list-item:hover,
.show-list-item:active,
.show-list-item:visited {
  background: linear-gradient(
    270deg,
    rgb(0 89 169 / 3%) 0%,
    rgb(0 89 169 / 9%) 100%
  ) !important;
}

.show-list-item::before {
  content: "";
  width: 10px;
  height: 10px;
  top: 9px;
  left: 4px;
  border: 2px solid var(--main-color);
  position: absolute;
  border-radius: 50%;
}
.show-list-item p {
  font-size: 15px;
}

.menuItem:hover > div svg {
  fill: var(--main-color) !important;
  color: var(--main-color) !important;
  display: inline-block;
  animation: swing ease-in-out 0.5s 1 alternate;
  background: linear-gradient(to bottom, #127d28 0%, #79b468 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
.common-links span {
  font-weight: bold;
  font-size: 14px;
  padding: 15px 20px 5px 20px;
  color: var(--neutral-secondary-color);
  display: inline-block;
}
@keyframes swing {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(10deg);
  }

  30% {
    transform: rotate(0deg);
  }

  40% {
    transform: rotate(-10deg);
  }

  50% {
    transform: rotate(0deg);
  }

  60% {
    transform: rotate(5deg);
  }

  70% {
    transform: rotate(0deg);
  }

  80% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
@keyframes sonar {
  0% {
    transform: scale(0.9);
    opacity: 1;
  }

  100% {
    transform: scale(2);
    opacity: 0;
  }
}

/* .menuIcon-wrapper {
  background: white;
  border: 1px solid #dee2e6;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.08));
  font-size: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  line-height: 25px;
  text-align: center;
  border-radius: 4px;
} */
.menuIcon-wrapper i {
  color: var(--neutral-main) !important;
}
.menuIcon-wrappe {
  width: 58px;
}

/**/
.crossIconContainer {
  cursor: pointer;
}

.arrowDown {
  transform: rotate(90deg);
}
.menuItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 16px 20px 16px 20px !important;
}
.menuItem p {
  margin: 0;
}
.menuItem:hover i {
  background: linear-gradient(
    270deg,
    rgb(0 89 169 / 3%) 0%,
    rgb(0 89 169 / 9%) 100%
  );
}
.menuItem:active .menuIndicator svg {
  transform: rotate(90deg);
}
.menuItem .menuIcon-wrapper i {
  font-size: 18px;
}
.menuItem:hover .menuIcon-wrapper i {
  color: var(--neutral-main) !important;
  display: inline-block;
  animation: swing ease-in-out 0.5s 1 alternate;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
.menu-name {
  transition: all 0.3s ease-in-out;
  pointer-events: none !important;
}
.activeMenu .menu-name {
}

.menuItem:hover .menu-name {
  color: var(--main-color);
}
.menuIndicator svg {
  transition: all 0.3s ease-in-out !important;
  transform-origin: center;
}
.menuIndicator svg:hover {
  transform: rotate(90deg);
}
.nested-option {
  max-height: 600px !important;
  /* min-height: 60px; */
}
.list-wrapper {
  white-space: nowrap;
  min-height: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.6s ease-in-out !important;

  margin: 0px 20px;
}

.list-wrapper li {
  padding: 5px 0;
  white-space: nowrap;
  position: relative;
}

.list-wrapper ul {
  list-style: none;
  padding: 0;
}

.collapsible-menu {
  min-height: 0;
  max-height: 0;
  overflow: hidden;
  white-space: nowrap;
  transition: all 0.5s ease-in-out !important;
  transition-timing-function: linear;
  margin: 0px 20px;
}
.opened {
  max-height: 600px;
  min-height: 60px;
}

.height-0 {
  height: 0 !important;
  overflow: hidden;
}
.menuContent {
  display: flex;
  align-items: center;
  gap: 17px;
}
.menuContent p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 600;
  color: var(--slate-gray);
  white-space: nowrap;
}
.child-wrapper {
  background: rgba(255, 255, 255, 0.1) !important;
  color: #fff;
}
.circle-indicator {
  display: block;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  border: 2px solid var(--main-color);
}

.content-wrapper::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.content-wrapper::-webkit-scrollbar-track {
  background: #525965;
}

.content-wrapper::-webkit-scrollbar-thumb {
  background: #f0f9ed;
}

.content-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (max-width: 992px) {
  .sidebar__wrapper aside {
    position: absolute !important;
    z-index: 9999;
  }
  .sidebar__mainContent {
    margin-left: 0;
  }
}
