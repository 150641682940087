.extraCardStyles {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.heading-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.heading-wrapper h1 {
  font-size: 28px;
  font-weight: 600;
}

.heading-wrapper p {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.action-wrapper {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.cancel-button {
  background-color: var(--secondary-color);
  color: var(--slate-gray);
  min-width: 150px;
}
.signIn-btn {
  min-width: 150px;
  padding: 10px 25px;
}

.icon-wrapper {
  width: 70px;
  height: 70px;
  background-color: var(--main-color);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
