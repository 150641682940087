.header {
  background-color: #ffff;
  color: var(--slate-gray);
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 45px;
  padding-right: 20px;
}
.header > h1 {
  font-size: 28px;
  font-weight: 600;
}

.btn-plus {
  min-width: fit-content;
  padding: 10px 25px !important;
}
.actions {
  display: flex;
  align-items: center;
  gap: 10%;
  margin-right: 30px;
}
.actions > img {
  border-radius: 100%;
  height: 50px;
}

@media (max-width: 670px) {
  .btn-plus {
    padding: 10px !important;
    font-size: 14px;
  }
}

@media (max-width: 425px) {
  .actions > img {
    display: none;
  }
  .actions {
    margin-right: 10px;
  }
}
