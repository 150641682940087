.btn-plus {
  padding: 10px 20px;
}
.cards-section {
  width: 100%;
  padding: 15px;
  padding-left: 40px;
}
.first-card {
  padding-top: 25px !important;
}
.commonCard-extra {
  padding: 20px !important;
  height: 100%;
}
.profile-img {
  height: 180px;
  width: 200px;
  display: flex;
  justify-content: flex-start;
  align-self: center;
}
.profile-img > img {
  border-radius: 100%;
  width: 100%;
  height: 100%;
}

.main-heading {
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.main-heading h1 {
  font-size: 28px;
  font-weight: 600;
}

.main-heading p {
  font-size: 16px;
  font-weight: 600;
  color: var(--slate-gray);
}
.single-detail {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}
.single-detail > svg {
  color: var(--main-color);
  font-size: 20px;
  font-weight: 600;
}
.single-detail p {
  font-size: 16px;
  font-weight: 400;
  color: var(--slate-gray);
}

.caseCard-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  /* padding: 10px; */
}
.caseCard {
  display: flex;
  min-height: 100px;
  align-items: center;
  padding: 10px 20px;
  width: 100%;
  /* max-width: 250px; */
  min-width: 125px;
  max-height: 230px;
}

.cases-picture-card {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cases-picture-card img {
  height: 60px;
}
.case-card-heading {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.case-card-heading h1 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
  width: 100%;
}
.sub-headings {
  /* padding: 10px; */
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.sub-headings p {
  font-size: 14px;
  color: var(--slate-gray);
  font-weight: 600;
}
.sub-headings p:first-of-type {
  margin-bottom: -10px;
}

.sub-headings h2 {
  font-size: 28px;
  color: var(--slate-gray);
  font-weight: 500;
}
.njhs-card-extraStyles {
  padding: 20px !important;
  margin-right: 20px;
}

.njhs-logo-wrapper {
  display: flex;
  justify-content: center;
  /* align-items: center; */
}
.njhs-logo-wrapper img {
  height: 110px;
}

.visit-wrapper {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn {
  padding: 10px 25px !important;
  font-size: 14px !important;
}

.carousal-wrapper {
  margin: 10px 20px;
}

.Carousel-heading {
  margin: 0px 30px;
  font-size: 28px;
  font-weight: 600;
}

@media (max-width: 575px) {
  .profile-img {
    width: 100%;
    justify-content: center !important;
    margin-bottom: 20px;
  }
  .profile-img > img {
    width: 60%;
  }
  .main-heading {
    text-align: center;
  }
  .main-heading h1 {
    font-size: 24px;
  }
  .main-heading > p {
    font-size: 14px;
  }
  .sub-headings {
    gap: 20px;
  }
  .caseCard {
    padding: 10px !important;
    min-height: 200px !important;
  }
  .details-wrapper {
    padding: 0px calc(var(--bs-gutter-x) * 0.5) !important;
  }
}

/* @media (max-width: 622px) {
  .details-wrapper {
    padding-left: 30px;
  }
} */

@media (max-width: 767px) {
  .njhs-description {
    text-align: center;
  }
  .btn {
    align-self: center !important;
  }
  .details-wrapper {
    padding-left: 35px;
  }
}

@media (max-width: 992px) {
  .caseCard-wrapper {
    width: 100%;
    flex-direction: row !important;
    gap: 30px;
    width: 100%;
  }
  .caseCard {
    display: flex;
    justify-content: center;
    margin: 10px 0px;
    max-height: 200px !important;
    width: 50%;
    min-height: 120px;
  }
  .case-card-heading h1 {
    margin-top: 10px;
  }
  .visit-wrapper {
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
  }
  .btn {
    margin-top: 10px;
    /* width: 30%; */
    align-self: flex-end;
  }
  .njhs-logo-wrapper {
    padding: 30px 0px;
    padding-top: 10px !important;
  }
  .njhs-logo-wrapper > img {
    height: 110px;
  }
}

@media (max-width: 1280px) {
  .single-detail > p {
    font-size: 14px;
  }
  .njhs-logo-wrapper > img {
    height: 100px;
  }
  .speciality-svg {
    font-size: 25px !important;
  }
}
