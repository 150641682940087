.form-wrapper {
  margin: 30px 0px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

@media (max-width: 700px) {
  .form-wrapper {
    gap: 0;
  }
}
