.Container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  border: 1px solid #ffffff91;
  border-radius: 15px;
}

.labelText {
  color: #425466;
  text-transform: capitalize;
  font-family: "Inter-regular";
  font-size: 14px;
  padding: 0px 5px;
}

.inputBox {
  font-size: 16px;
  color: #000;
  letter-spacing: 1.4px;
  padding: 15px 20px;
  width: 100%;
  background-color: transparent;
  outline: none;
  border: 1px solid var(--border-color);
}

.inputBoxSalutation {
  width: 10%;
  padding: 15px 0px;
  padding-left: 10px;
}

.inputBox:disabled {
  cursor: not-allowed;
}

.noBorder {
  border: none;
}

.inputPassContainer {
  display: flex;
  background: var(--white-color);
  position: relative;
  overflow: hidden;
  box-shadow: 2px 3px 8px 2px #a1a1a324;
}

.inputPassContainer input::placeholder {
  color: var(--placeholder-color);
  font-family: "Inter-regular";
  font-size: 12px;
  text-transform: capitalize;
}
.inputPassContainer textarea::placeholder {
  color: var(--placeholder-color);
  font-family: "Inter-regular";
  font-size: 12px;
  text-transform: capitalize;
}

.inputPassContainer input:disabled {
  background-color: var(--disabled-input-color);
  color: #c9c9c9;
  user-select: none;
}
.inputPassContainer textarea:disabled {
  background-color: var(--disabled-input-color);
  color: #c9c9c9;
  user-select: none;
}

.inputPassContainer .passwordIcon {
  position: absolute;
  right: 15px;
  top: 50%;
  bottom: 50%;
  transform: translateY(-50%);
  color: #cacaca;
}

.errorText {
  color: red;
}

.messageText {
  font-size: 12px;
  color: var(--text-color-gray);
  margin-left: 10px;
}

.disabled {
  color: var(--placeholder-color);
}

.leftIconBox {
  position: absolute;
  left: 20px;
  top: 55%;
  bottom: 45%;
  transform: translateY(-50%);
  height: max-content;
}

.rightIcon {
  position: absolute;
  right: 14px;
  top: 26px;
  /* bottom: 45%; */
  transform: translateY(-50%);
  height: max-content;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*  Firefox  */
input[type="number"] {
  -moz-appearance: textfield;
}

.eye-icon-wrapper {
  display: flex;
  align-self: center;
  margin-right: 15px;
}
