.extraCardStyle {
  max-height: 450px;
  min-width: 250px;
  height: 100%;
  /* margin-top: 30px; */
  padding: 30px 20px !important;
  padding-bottom: 60px !important;
}

.profile-img {
  width: 150px;
  height: 150px;
  margin: auto;
  overflow: hidden;
  border-radius: 100%;
}
.profile-img > img {
  height: 100%;
  width: 100%;
  transform: scale(1.5);
  margin-top: 35px;
}

.main-heading {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}
.main-heading h1 {
  font-size: 20px;
  font-weight: 600;
  color: var(--main-color);
}

.main-heading p {
  font-size: 14px;
  font-weight: 400;
  color: var(--slate-gray);
}

.view-btn {
  /* text-align: center; */
  position: absolute;
  bottom: 7%;
  left: 53%;
  transform: translateX(-55px);
}

.view-btn p {
  font-size: 16px;
  font-weight: 600;
  color: var(--main-color);
  text-decoration: underline;
  cursor: pointer;
}
