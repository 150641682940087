table {
  border-collapse: collapse !important;
  /* border-collapse: separate !important; */
  /* border-spacing: 0 8px !important; */
  width: 100%;
  /* border: 4px solid red !important; */
  position: relative;
  border-collapse: initial !important; /* or collapse */
  border-spacing: 0px;
}

html {
  scrollbar-width: none;
}
tbody {
  overflow-x: auto;
  width: 100%;
  /* height: 200px; */
}

th {
  background: var(--ghost-white) !important;
  padding: 9px 8px !important;
  text-align: initial;
  color: var(--cadet-blue) !important;
  border-top: 0px solid var(--gainsboro) !important;
  font-size: 14px;
  font-family: "Inter-semiBold";
  text-transform: uppercase;
  padding: 19px 24px !important;
  white-space: nowrap;
}
tr {
  border-style: unset;
  border-radius: 12px !important;
  border: 1px solid #dee2e6;
  background: var(--white-color);
}
th td {
  /* background: var(--) !important; */
  white-space: nowrap;
}
tr td {
  padding: 19px 24px !important;
  /* text-align: center; */
  color: #676767 !important;
  vertical-align: middle;
  background: var(--white-color);
  border-bottom: 1px solid var(--gainsboro);
}
th td {
  /* background: var(--) !important; */
  border-bottom: 0px solid !important;
}
th:last-child,
td:last-child {
  /* position: -webkit-sticky;
  z-index: inherit;
  position: relative !important;
  right: 0 !important; */
}
/* th:last-child {
  position: fixed !important;
  top: 0;
} */
.header {
  margin-bottom: 20px;
}
.pageMain {
  height: calc(100vh - 120px);
  scrollbar-color: track-color thumb-color;
  scrollbar-width: thin;
  overflow: auto;
}
.pageMain::-webkit-scrollbar {
}
.table-data-records {
  overflow-y: scroll;
}

.table-data-records::-webkit-scrollbar {
  width: 5px;
  height: 5px;

  /* Hide scrollbar for WebKit/Blink */
  /* Chrome, Safari, Opera */
}

/* Hide vertical scrollbar for Firefox */
/* Track */
.table-data-records::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
.table-data-records::-webkit-scrollbar-thumb {
  background: var(--main-color);
}

table {
  position: relative;
  border-collapse: initial !important; /* or collapse */
  border-spacing: 0px;
}
/* th:last-child,
td:last-child {
  position: -webkit-sticky;
  position: relative !important;
  right: 0% !important;
  z-index: inherit;
}
thead th:first-child,
tfoot th:first-child {
  z-index: 5;
} */

/* For Firefox */
/* For Firefox */

@supports (-moz-appearance: none) {
  /* For Firefox */
  .table-data-records {
    scrollbar-width: thin;
    scrollbar-color: var(--main-color) #fff;
  }

  /* Track */
  .table-data-records {
    scrollbar-track-color: #fff;
  }

  /* Handle */
  .table-data-records {
    scrollbar-thumb-color: var(--main-color);
  }
}

/* For WebKit-based browsers (Chrome, Safari, etc.) */

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  /* Hide vertical scrollbar */
  .table-data-records {
    overflow-y: hidden;
  }
}

@media (max-width: 767px) {
  tr td {
    padding: 19px 24px !important;
    /* text-align: center; */
    color: #676767 !important;
    vertical-align: middle;
    background: var(--white-color);
    border-bottom: 1px solid var(--gainsboro);
    font-size: 12px;
  }
  tr th {
    font-size: 12px;
  }
}

.css-3bmhjh-MuiPaper-root-MuiPopover-paper {
  box-shadow: none !important;
  border-radius: 8px !important;
  background-color: var(--ghost-white) !important;
}
