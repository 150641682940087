.extraCardStyle {
  max-height: 450px;
  min-width: 250px;
  height: 100%;
  /* margin-top: 30px; */
  padding: 10px;
  cursor: pointer;
}

.map-img {
  display: flex;
  justify-content: center;
  align-self: center;
}
.map-img > img {
  /* height: 100%;
  max-height: 200px;
  max-width: 300px; */
  border-start-start-radius: 16px;
  border-start-end-radius: 16px;
}

.main-heading {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  justify-content: center !important;
}
.main-heading h1 {
  font-size: 18px;
  font-weight: 600;
  text-decoration: underline;
  color: var(--main-color);
}

.main-heading p {
  font-size: 14px;
  font-weight: 400;
  color: var(--slate-gray);
  text-align: center;
}
