.wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  align-items: center;
  width: 100%;
}
.noData {
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  max-width: 55rem;
  text-align: center;

  border-radius: 12px;
}
.noData p {
  color: var(--raven);
  font-family: "Inter-semiBold";
  font-size: 1rem;
}
.iconWrapper {
  text-align: center;
  margin-bottom: 1rem;
}

@media (max-width: 767px) {
  .noData p {
    font-size: 14px;
  }
}
