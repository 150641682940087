.wrapper {
  padding: 20px 24px;
  background: var(--white-color);
}

.wrapper p {
  color: var(--slate-blue);
}

@media (max-width: 767px) {
  .wrapper {
    flex-direction: column;
  }
  .wrapper p {
    font-size: 14px;
  }
}
