/* pagination */

.gap {
  gap: 12px;
}
.indicator__name {
  font-family: "Inter-medium";
  color: var(--slate-gray);
}
.indicator__outof {
  min-width: 36px;
}
.wrapper {
  gap: 26px;
}

@media (max-width: 767px) {
  .fitInResponsive {
    gap: 4px !important;
    font-size: 12px;
  }

  .wrapper {
    gap: 8px !important;
  }
}
