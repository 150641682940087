.table-wrapper {
  background-color: #ffff;
  margin: 5%;
}
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin: 10px 30px;
  height: 35px;
  background-color: transparent;
  border: 1px solid var(--secondary-color);
  color: var(--slate-gray);
}

.btnStyles {
  width: 20% !important;
}
