.indicatorFlag {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

@media (max-width: 767px) {
  .wrapper p {
    font-size: 12px;
  }
}
