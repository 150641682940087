.select-wrapper {
  box-shadow: 0px 0px 1px 0px #32324733;

  box-shadow: 0px 1px 2px 0px #32324714;
}

.label {
  margin-bottom: 3px;
  color: var(--primary-color);
  text-transform: capitalize;
  font-family: "Inter-Regular";
  font-size: 24px;
}

.disabled {
  color: red;
}
