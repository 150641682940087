.commonCard {
  border-radius: var(--border-radius);
  border: 1px solid #dee2e6;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.08));
  color: var(--neutral-main);
  z-index: 1;
  padding: 50px;
  background-color: #fff;
}

@media (max-width: 578px) {
  .commonCard {
    padding: 30px !important;
  }
}
