/*
**********************
Utility classes Start
**********************
*/
.overflow-x-hidden {
  overflow-x: hidden;
}
.apply-transition {
  transition: 0.3s ease-in-out;
}
.ml-5px {
  margin-left: 5px;
}
.seprator-padding {
  width: 90% !important;
  margin: auto;
}
.mt-34 {
  margin-top: 0px;
}
/*
  **********************
  Utility classes End
  **********************
  */
.sidebar__wrapper {
  background: linear-gradient(180deg, #fff 0%, #dee2e6 100%);
}
.toggle {
  transition: 0.3s ease-in-out;
}
.sidebar__wrapper aside {
  overflow-y: scroll;
  height: 100vh;
  border-right: 1px solid #e7e4e4;
  position: fixed;
  z-index: 200;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow: visible;
}
.sidebar__wrapper aside::-webkit-scrollbar {
  display: none;
}
.sidebar__sideContent {
  width: 250px !important;
  background: #fff;
  height: 100%;
  transition: all 0.5s ease-in-out;
}

.sidebar__mainMenu {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.sidebar__mainContent {
  margin-left: 250px;
  background: #f7fafc;
  transition: 0.3s ease-in-out;
  height: 100vh;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
  scrollbar-width: thin;
}
.sidebar__mainContent::-webkit-scrollbar-thumb {
  display: none;
}
.sidebar__mainContent::-webkit-scrollbar {
  width: 0;
}
.width-0 {
  width: 58px !important;
}
.brand-logo {
  /* margin-top: 0.8rem; */
  height: 7vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.short-logo {
  height: 21px !important;
  margin-right: auto;
}
.brand-logo img {
  height: 60px;
}
.margin-left-0 {
  margin-left: 58px;
}
.sidebarList {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-x: hidden;
  height: 100%;
  overflow-y: scroll !important;
  max-height: 100vh;
  padding-bottom: 3rem;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.sidebarList::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sidebarList {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.inputSearch {
  padding: 10px 15px;
  padding-block-start: 40px;
}
/*
  =====================
  Styling for fabars
  when user clicks this 
  green bars icon the sidebar will open
  =====================
  */
.sidebar__openerToggle {
  position: absolute;
  right: -35px;
  top: 25px;
  transition: 0.6s ease-in-out;
  width: 35px;
  transition-delay: 0.2s;
  background-color: var(--main-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 11px !important;
  z-index: 9 !important;
  border-radius: 0px 4px 4px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.crossIconContainer {
  cursor: pointer;
  color: var(--main-color);
  margin-top: 8px;
}
.sidebar__wrapper {
  display: flex;
  height: 100vh;
}
.sidebar-side-transition {
  transition: 0.3s ease-in-out;
}
.sidebar__header p {
  color: var(--neutral-main);
  font-weight: bold;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  margin: 0;
}
.sidebar__header p:hover {
  color: var(--main-color);
  text-decoration: underline;
}
.sidebar__header {
  white-space: nowrap;
  overflow-x: hidden;
  height: fit-content;
  position: sticky;
  top: 20px;
  background: var(--neutral-main-bg);
  z-index: 9999999999;
  padding-top: 10px;
  padding-bottom: 57px;
}
/* Searchbar Styling*/
.searchbar-styling {
  background: #fff;
  border-radius: 0.375rem;
  height: 38px;
  border: 1px solid #dee2e6;
  overflow-y: hidden;
  width: 247.19px;
}
.searchbar-container {
  padding: 10px 15px;
}
.searchbar-styling > div > div input {
  background: white;
  border-right: 1px solid #dee2e6;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.08));
  padding: 0.375rem 0.75rem;
}
/* .content-wrapper {
    height: calc(100vh - 133px);
  
    overflow-y: scroll;
  } */
/*Styling about active menu*/
.menuItem {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px 10px 20px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.menuItem:hover,
.menuItem:visited {
  background: linear-gradient(
    270deg,
    rgb(201 189 189 / 3%) 0%,
    rgb(203 86 86 / 9%) 100%
  );
}
.menuItem p {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 600;
  color: var(--neutral-main) !important;
}
.menuItem:hover p {
  color: var(--main-color) !important;
}
.activeMenu {
  font-size: 18px;
  letter-spacing: 0.5px;
  transition: all 0.3s ease-in-out;
}
.activeMenu > div svg {
  color: var(--main-color) !important;
}
.activeMenu p {
  color: var(--main-color) !important;
}

.menuItem:hover > div svg {
  color: var(--main-color) !important;
  display: inline-block;
  animation: swing ease-in-out 0.5s 1 alternate;
  background: linear-gradient(to bottom, #127d28 0%, #79b468 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
.common-links span {
  font-weight: bold;
  font-size: 14px;
  padding: 15px 20px 5px 20px;
  color: var(--neutral-secondary-color);
  display: inline-block;
}
@keyframes swing {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(10deg);
  }

  30% {
    transform: rotate(0deg);
  }

  40% {
    transform: rotate(-10deg);
  }

  50% {
    transform: rotate(0deg);
  }

  60% {
    transform: rotate(5deg);
  }

  70% {
    transform: rotate(0deg);
  }

  80% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
@keyframes sonar {
  0% {
    transform: scale(0.9);
    opacity: 1;
  }

  100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes logout {
  0% {
  }
  100% {
    transform: scaleX(-1);
  }
}

.menuIcon-wrapper {
  background: white;
  border: 1px solid #dee2e6;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.08));
  font-size: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  line-height: 25px;
  text-align: center;
  border-radius: 4px;
}

/*=================
  list component styling
   ================
  */

.menuItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px 31px 10px 20px;
}
.menuItem p {
  margin: 0;
}

.menuItem:hover {
  background: linear-gradient(
    270deg,
    rgb(201 189 189 / 3%) 0%,
    rgb(203 86 86 / 9%) 100%
  );
}
.menuItem:hover .menuIndicator svg {
  transform: rotate(90deg);
}
.menuItem:hover .menuIcon-wrapper svg {
  color: var(--main-color) !important;
  display: inline-block;
  animation: swing ease-in-out 0.5s 1 alternate;
  background: linear-gradient(to bottom, #127d28 0%, #79b468 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
.menu-name {
  transition: all 0.3s ease-in-out;
  pointer-events: none !important;
}
.activeMenu .menu-name {
  color: var(--main-color) !important;
}
.activeMenu .menuContent p {
  color: var(--main-color) !important;
}

.menuItem:hover .menu-name {
  color: var(--main-color);
}
.menuIndicator svg {
  transition: all 0.3s ease-in-out !important;
  transform-origin: center;
}
.menuIndicator svg:hover {
  transform: rotate(90deg);
}
.dropdown-list {
  transition: all 0.5s ease-in-out;
  overflow: hidden;
  margin: 10px 20px;
}
.list-wrapper {
  border: 1px solid #dee2e6;
  cursor: pointer;
  background: #fff;
  border-radius: 8px;
}
.list-wrapper li {
  padding: 10px;
  white-space: nowrap;
}
.height-0 {
  height: 0 !important;
}
.menuContent {
  display: flex;
  align-items: center;
  gap: 10px;
}
.menuContent p {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 600;
  color: var(--neutral-main) !important;
  white-space: nowrap;
}
/*=================
  list component styling end
   ================
  */

/* ================
  content-wrapper section scroll bar designing
  ==================*/

/* .content-wrapper::-webkit-scrollbar {
    width: 10px;
    height: 5px;
  } */

/* Track */
/* .content-wrapper::-webkit-scrollbar-track {
    background: #525965;
  } */

/* Handle */
/* .content-wrapper::-webkit-scrollbar-thumb {
    background: #f0f9ed;
  } */

/* Handle on hover */
/* .content-wrapper::-webkit-scrollbar-thumb:hover {
    background: #555;
  } */
.seprator-line {
  border: 2px solid;
  width: 100%;
  border: 1px solid var(--gainsboro);
}

.down-actions-wrapper {
  margin-top: 25px;
}
.down-actions {
  margin-top: 20px;
  align-items: center;
  justify-content: center;
}
.logout-icon:hover > div > div > div > svg {
  animation: logout ease-in-out 0.5s;
  animation-fill-mode: forwards;
}

.forLongLogo {
  padding-inline: 20px;
}
.forshortLogo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.full-toggle-mode {
  justify-content: flex-start !important;
  padding: 16px 20px 16px 20px !important;
}
.toggle-mode-wrapper {
  margin-top: 62px;
  gap: 7px;
  display: flex;
  padding: 10px;
}
.toggle-mode-wrapper p {
  font-size: 14px;
}
@media (max-width: 992px) {
  .sidebar__wrapper aside {
    position: absolute !important;
    z-index: 999999999;
  }
  .sidebar__mainContent {
    margin-left: 0px;
  }
  .width-0 {
    width: 0px !important;
  }
}

.profile-image-wrapper {
  text-align: center;
  padding: 10px 0px;
  height: fit-content;
  display: none;
}
.profile-image-wrapper > img {
  height: 100px;
  border-radius: 100%;
}

@media (max-width: 425px) {
  .profile-image-wrapper {
    display: block;
  }
}
