.main-div {
  width: 100%;
  padding: 0px 20px;
}

.filters-wrapper {
  margin: 20px;
  margin-bottom: 0px;
}

.extraInputBox {
  padding: 10px 20px;
}
