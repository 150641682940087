.dr-wrapper {
  display: flex;
}

.extraCardStyles {
  padding: 20px 50px;
  margin: 20px 10% !important;
}

.btn-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-right: 3%;
  margin-top: 20px;
}

.btn {
  width: 20%;
}

.extraCardStyles2 {
  padding: 50px;
  margin: 20px 10% !important;
}

.heading-wrapper {
  margin-bottom: 10px;
}
.heading-wrapper h1 {
  font-size: 20px;
  font-weight: 600;
}

.details-wrapper {
  padding: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detail-description p {
  font-weight: 400;
  font-size: 14px;
}
.name-h1 {
  color: var(--main-color);
  font-weight: 600 !important;
}

.profile-img,
.profile-img-heading {
  display: flex;
  justify-content: flex-start;
  align-self: center;
}
.profile-img > img {
  height: 100px;
  border-radius: 100%;
  width: 100px;
}

.main-heading {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.main-heading h1 {
  font-size: 16px;
  font-weight: 600;
}

.main-heading p {
  font-size: 14px;
  font-weight: 400;
  color: var(--slate-gray);
}

.upload-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  height: 100%;
}
.upload-btn p {
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}
.upload-btn svg {
  cursor: pointer;
}

.extra-btn {
  padding: 10px 25px;
}

@media (max-width: 768px) {
  .btn {
    width: 100%;
  }
  .details-wrapper {
    gap: 20px;
    flex-direction: column;
  }
  .profile-img {
    justify-content: center !important;
    width: 100%;
  }
  .main-heading {
    align-items: center;
    width: 100%;
  }
  .profile-img > img {
    max-width: 200px;
  }
  .upload-btn {
    width: 100%;
  }
}
